body {
  overflow-x: hidden;
  background: white;
}
.App {
  width: 100vw;
  height: 100vh;
}

.main-container {
  display: flex;
}
.nav-bar {
  /*height: 100*/
}


.content {
  margin: 50px 0 0 0;
  padding: 0;
  width: 77%;
}

.mdk--Login-Reg {
  width: 100%;
}

.mdk--pagination {
  cursor: pointer;
}


/*Testing*/

.mdk-navbar-left {
  
  background-color: #3a3b68;
  height: 100vh;
  position: fixed;
  width: 230px;
  top: 0;
  padding: 70px 0 0 0;
}
.mdk-navbar-left li a i {
  font-size: 20px;
  margin: 0 5px 0 0;
}

.mdk-right-content {
  padding: 100px 30px 20px 10px;
}

#mdk-main-part .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.mdk-navbar-left ul {
  list-style: none;
  
}

.mdk-navbar-left li {
  width: 100%;
  padding:10px 0;  
  transition: all 0.5s ease-in-out 0s;
  margin : 0 0 5px 0;
}
.mdk-navbar-left li:hover {
  background: #392c6a;
  text-decoration: none;  
}
/*
#mdk-main-part .col-sm-2 {
  background-color: #3c4b64;
}*/

.mdk-navbar-left li a {
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  display: block;
  width: 100%;
  transition: all 0.5s ease-in-out 0s;
}
.mdk-navbar-left li a:hover {
  text-decoration: none;
}
.mdk-navbar-left li a:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

.mdk-right-part {
  width: 100%;
}

.mdk-pagination .pagination {
  flex-wrap: wrap;
}


@media all and (max-width:1199px){
  .mdk-navbar-left li {
    padding: 10px;
  }
  .mdk-navbar-left li:hover {
    padding: 10px;
  }
  .dashboard-logo {
    max-width: 100%;
    height: 20px;
  }
}
@media all and (max-width:991px){
  .mdk-navbar-left {
    height: auto;
    width: 100%!important;
    position: relative;
  }
  #mdk-main-part .col-sm-2 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  #mdk-main-part .col-sm-10 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .mdk-right-content {
    padding: 50px 30px 20px 10px;
  }
  .header {
    z-index: 999;
    background: #fff;
  }
  .mdk-navbar-left {
    margin: 70px 0 0 0;
    padding: 0;
  }
  .header .col-sm-2 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .header .col-sm-10 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .dashboard-logo {
    height: 40px;
background: #3a3b68;
padding: 10px;
  }
}
@media all and (max-width:600px){ 
  .right-title {
    font-size: 12px;
  }
  .dashboard-logo {
    height: 35px;
  }

}

/*start new css line css here*/


.right-panel-new {
width: 100%;
  padding: 0 0 0 250px;
  margin: 0;
}
.main-white {
width:100%;
padding:10px 20px;
margin: 15px 0 0 0;
}
.main-content {
width:100%;
}
/*end new css line css here*/

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  /* font-family:Roboto; */
}

