a {
  color: rgb(18, 110, 54) !important;
}
body {
	font-family: 'Inter', sans-serif;
	overflow-x:hidden;
  color: rgb(10, 10, 12);
}
#main-nav {
	display:none;
	background:#072b15;
	position:fixed;
	top:0;
	width:100%;
}
.banner-content {
	width:100%;
	height:100%;
	display:flex;
	position:absolute;
	top:0;
	align-items:center;
}
.banner-title {
	color: #fff;
    font-size:70px;
    line-height:70px;
	padding:0;
	margin:0;
}
.banner-overlay {
	width:100%;
	height:100%;
	position:absolute;
	top:0;
	background: linear-gradient(0deg, #072B15 0%, rgba(7, 43, 21, 0.64) 37.27%, rgba(7, 43, 21, 0.16) 100%);;
}
.banner-title span {
	color: transparent !important;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #ffffff;    
}
#banner-area p {
	font-size:16px;
	padding:0;
	margin:0;
	color:#fff;
	letter-spacing:1px;
}
#banner-area .button {
	float:none;
	margin:30px auto 0 auto;
	border:2px solid #fff;
	color:#fff;
}
#banner-area .button--wayra:hover::before {
	background:#fff;
	color:#072b15;	
}
#banner-area .button--wayra:hover {
	color:#072b15;
	border-color: #fff;
}
#banner-area .content-top {
	margin:25px 0 0 0;
}
.logo {
	margin:3px 0 0 0;
}
.mobile-content {
	padding:2em 0;
	display:none;
}

/*start top menu*/
.nav-new {
  display: block;
  background:transparent;
  position:fixed;
  top:0;
  width:100%;
  z-index:999;
  padding:10px 0;
}
.nav-new ul {
	margin-bottom:0;
}
.menu-new { display: block; }

.menu-new li {
  display: inline-block;
  position: relative;
  z-index: 100;
}

.menu-new li:first-child { margin-left: 0; }

.menu-new li a {
  font-weight:normal;
  text-decoration: none;
  padding:7px 20px;
  display: block;
  color: #fff;
  transition: all 0.2s ease-in-out 0s;
}

.menu-new li a:hover,
.menu-new li:hover>a {
  color: #fff;
  background:rgb(7, 43, 21);
  border-radius:4px;
}

.menu-new ul {
  visibility: hidden;
  opacity: 0;
  margin: 0;
  padding: 0;
  width: 170px;
  position: absolute;
  left: 0px;
  background: #fff;
  z-index: 99;
  transform: translate(0, 20px);
  transition: all 0.2s ease-out;
}

.menu-new ul:after {
  bottom: 100%;
  left: 20%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 6px;
  margin-left: -6px;
}

.menu-new ul li {
  display: block;
  float: none;
  background: none;
  margin: 0;
  padding: 0;
}

.menu-new ul li a {
  font-size: 14px;
  font-weight: normal;
  display: block;
  color: #797979;
  background: #fff;
  text-align:left;
}

.menu-new ul li a:hover,
.menu-new ul li:hover>a {
  background: #FC6D58;
  color: #fff;
}

.menu-new li:hover>ul {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

.menu-new ul ul {
  left: 169px;
  top: 0px;
  visibility: hidden;
  opacity: 0;
  transform: translate(20px, 20px);
  transition: all 0.2s ease-out;
}

.menu-new ul ul:after {
  left: -6px;
  top: 10%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #fff;
  border-width: 6px;
  margin-top: -6px;
}

.menu-new li>ul ul:hover {
  visibility: visible;
  opacity: 1;
  transform: translate(0, 0);
}

.responsive-menu {
  display: none;
  width: 100%;
  padding: 20px 15px;
  background: #E95546;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
}

.responsive-menu:hover {
  background: #E95546;
  color: #fff;
  text-decoration: none;
}

a.homer { background: #FC6D58; }
 @media (min-width: 768px) and (max-width: 979px) {

.mainWrap { width: 768px; }

.menu-new ul { top: 37px; }

.menu-new li a { font-size: 12px; }

a.homer { background: #E95546; }
}
 @media (max-width: 767px) {

.mainWrap {
  width: auto;
  padding: 50px 20px;
}

.menu-new { display: none; }

.responsive-menu { display: block; }

.nav-new {
  margin: 0;
  background: none;
}

.menu-new li {
  display: block;
  margin: 0;
}

.menu-new li a {
  background: #fff;
  color: #797979;
}

.menu-new li a:hover,
.menu-new li:hover>a {
  background: #FC6D58;
  color: #fff;
}

.menu-new ul {
  visibility: hidden;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  transform: initial;
}

.menu-new li:hover>ul {
  visibility: visible;
  opacity: 1;
  position: relative;
  transform: initial;
}

.menu-new ul ul {
  left: 0;
  transform: initial;
}

.menu-new li>ul ul:hover { transform: initial; }
}
.menu-right {
	width:100%;
	text-align:right;
}
/*end top menu*/

.mobile-logo {
	position:absolute;
	top:15px;
	left:10px;
	border:1px solid red;
	width:200px;
}
.menu-bar {
	font-size:22px!important;
	color:#fff;
}

.nav-new.active {
    background:#072b15;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
	transition:all 0.5s ease-in-out 0s;
}
.nav-new.active .menu-new li a:hover {
	background:rgb(7, 43, 21);
}
.underneath-banner {
	padding:4em 0;
	position:relative;
}
.body-widget {
	width:100%;
}
.content-bg {
	background:#f6f6f6;
	padding:35px 30px;
	border-top: 3px solid transparent;
	transition:all 0.5s ease-in-out 0s;
}
.content-bg:hover {	
	border-top: 3px solid #072b15;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.heading-title2 {
	font-size:22px;
	padding:0 0 10px 0;
	margin:0;
	color:rgb(29, 41, 57);
}
.heading-title {
	font-size:40px;
	padding:0;
	margin:0;
	color:rgb(29, 41, 57);
	font-weight:700;
}
.heading-title-black {
	font-size:40px;
	padding:0;
	margin:0;
	color:rgb(29, 41, 57);
	font-weight:700;
}
#underneath-banner p {
	font-size:15px;
	padding:0;
	margin:0;
	color:#676c71;
	line-height:26px;
	letter-spacing:0.5px;
}
.common-top {
	width:100%;
	margin:50px 0 0 0;
}
.common-top1 {
	width:100%;
	margin:30px 0 0 0;
}
.content-top {
	width:100%;
	margin:15px 0 0 0;
}
.btn-top {
	width:100%;
	margin:20px 0 0 0;
}
.get-started {
	padding:4em 0;
	background:#072b15;
}
#underneath-banner .img-fluid {
	border-radius: 0 0 80px 0;
}
.about-right-bg {
	width:100%;
	background:url(../images/about-bg.png) top right no-repeat;
	padding:30px 30px 0 30px;
}
.heading-title-white {
	font-size:40px;
	padding:0;
	margin:0;
	color:#fff;
	font-weight:700;
}
.slider-img {
	width:100%;
	height:100vh;
	object-fit:cover;
	-webkit-animation: zoom 40s;
  animation: zoom 40s;
}
@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(1, 1);
  }
  to {
    -webkit-transform: scale(1.5, 1.5);
  }
}

@keyframes zoom {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.5, 1.5);
  }
}
.login-btn a {
	padding:5px!important;
	margin:0;
	border:2px solid #fff;
	width:150px;
	text-align:center;
	border-radius:4px;
	margin:0 0 0 15px;
  color: white !important;
}
.login-btn a:hover {
	border:2px solid #072b15;
}
.assestment a {
	font-size:24px;
	padding:0;
	margin:0;
	color:#fff;
	display:block;
	transition:all 0.5s ease-in-out 0s;
}
.assestment a:hover {
	color:#c3c3c3;
	text-decoration:none;
}
#get-started .img-fluid {
	margin:0 0 15px 0;
	border:4px solid #fff;
}
.started-widget {
	width:100%;
}
.services-area {
	padding:4em 0;
	background:#f6f6f6;
	position:relative;
}
.services-white {
	width:100%;
	padding:40px 20px;
	background:#fff;
	box-shadow: 5px 5px 24px 0px rgba(2, 45, 98, 0.1);
	border-radius: 5px;
	margin:0 0 30px 0;
	transition:all 0.5s ease-in-out 0s;
}
.services-white:hover {
	transform:translate(0,-10px);
}
.img-icons {
	height:100px;
}
.services-title {
	font-size:22px;
	padding:20px 0 0 0;
	margin:0;
}
#services-area .button {
	float:none;
	margin:20px auto 0 auto;
}


/*services animation css here*/
.service-another-shape .shape-1 {
  position: absolute;
  top: 10%;
  left: 10%;
  -webkit-animation: animationFramesOne 15s linear infinite;
          animation: animationFramesOne 15s linear infinite;
}

.service-another-shape .shape-2 {
  position: absolute;
  top: 50%;
  left: 3%;
  -webkit-animation: rotated360 6s infinite linear;
          animation: rotated360 6s infinite linear;
}

.service-another-shape .shape-3 {
  position: absolute;
  top: 80%;
  left: 1%;
  -webkit-animation: running-in 16s infinite linear;
          animation: running-in 16s infinite linear;
}

.service-another-shape .shape-4 {
  position: absolute;
  top: 13%;
  right: 5%;
  -webkit-animation: running-in 10s infinite linear;
          animation: running-in 10s infinite linear;
}

.service-another-shape .shape-5 {
  position: absolute;
  top: 35%;
  right: 3%;
  -webkit-animation: running-in 16s infinite linear;
          animation: running-in 16s infinite linear;
}

.service-another-shape .shape-6 {
  position: absolute;
  top: 55%;
  right: 1%;
  -webkit-animation: rotated360 6s infinite linear;
          animation: rotated360 6s infinite linear;
}
@-webkit-keyframes rotated360 {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(-360deg);
    transform: rotateZ(-360deg);
  }
}

@keyframes rotated360 {
  0% {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(-360deg);
    transform: rotateZ(-360deg);
  }
}

@-webkit-keyframes rotate-in {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

@keyframes rotate-in {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

@-webkit-keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
    transform: translate(0px, 0px) rotate(0deg);
  }
}

@-webkit-keyframes opacity-option {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .05;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacity-option {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .05;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes running-in {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(-10px, 150px);
    transform: translate(-10px, 150px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes running-in {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  50% {
    -webkit-transform: translate(-10px, 150px);
    transform: translate(-10px, 150px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@-webkit-keyframes movebounce-in {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(70px);
    transform: translateX(70px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes movebounce-in {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(70px);
    transform: translateX(70px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-webkit-keyframes movebounce-up {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes movebounce-up {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}
@-webkit-keyframes rotaed360 {
  0% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(-360deg);
            transform: rotateZ(-360deg);
  }
}

 

@keyframes rotaed360 {
  0% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(-360deg);
            transform: rotateZ(-360deg);
  }
}

.blog-shape .shape1 {
  position: absolute;
  top: 30%;
  left: 2%;
  -webkit-animation: animationFramesOne 10s linear infinite;
          animation: animationFramesOne 10s linear infinite;
}

.blog-shape .shape2 {
  position: absolute;
  top: 60%;
  left: 4%;
  -webkit-animation: animationFramesOne 15s linear infinite;
          animation: animationFramesOne 15s linear infinite;
}

.blog-shape .shape3 {
  position: absolute;
  top: 50%;
  right: 2%;
  -webkit-animation: flash 5s infinite linear;
          animation: flash 5s infinite linear;
}

.blog-shape .shape4 {
  position: absolute;
  top: 80%;
  right: 4%;
  -webkit-animation: rotated360 6s infinite linear;
          animation: rotated360 6s infinite linear;
}
/* end animation css here*/
/*start footer css here*/
.footer-new {
	padding:3em 0;
	background: rgba(2, 45, 98, 0.1);
}
#footer-new ul {
	list-style:none;
	margin:0;
	display:flex;
	justify-content:center;
}
#footer-new li {
	display:inline-block;
	margin:0 30px 0 0;
}
#footer-new li:last-child {
	margin:0;
}
#footer-new li a {
	font-size:16px;
	letter-spacing:1px;
	font-weight:normal;
	color:#676c71;
	transition:all 0.5s ease-in-out 0s;
}
#footer-new li a:hover {
	color:#072b15;
	text-decoration:none;
}
#footer-new li a:focus {
	color:#072b15;
	text-decoration:none;
	outline:none;
	box-shadow:none;
}
.footer-social a {
	width:40px;
	height:40px;
	line-height:40px;
	display:inline-block;
	background:#072b15;
	text-align:center;
	color:#fff;
	margin:0 0 0 5px;
	border-radius:50%;
	transition:all 0.5s ease-in-out 0s;
	border:2px solid transparent;
}
.footer-social a:hover {
	background:transparent;
	border:2px solid #072b15;
	color:#072b15;
}
.social-top {
	width:100%;
	margin:20px 0;
}
#footer-new p {
	font-size:15px;
	padding:0;
	margin:0;
	color:#676c71;
}
/*end footer css here*/

/* BUTTON*/

.button {
  float: left;
  min-width: 150px;
  max-width: 200px;
  display: block;   
  padding:10px;
  border: none;
  background: none;
  color: inherit;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  text-align:center;
  border:2px solid #072b15;
  color:#072b15;
  border-radius:40px;
  font-size:16px;
  letter-spacing:1px;
}
.button:hover {
  text-decoration:none;
}
.button:focus {
  outline: none;
}
.button > span {
  vertical-align: middle;
}
/* Wayra */
.button--wayra {
  overflow: hidden;
  width: 245px;
  -webkit-transition: border-color 0.3s, color 0.3s;
  transition: border-color 0.3s, color 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--wayra::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 150%;
  height: 100%;
  background: #37474f;
  z-index: -1;
  -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
  transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s, background-color 0.3s;
  transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
}
.button--wayra:hover {
  color: #fff;
  border-color: #072b15;
}
.button--wayra.button--inverted:hover {
  color: #3f51b5;
  border-color: #fff;
}
.button--wayra:hover::before {
  opacity: 1;
  background-color:#072b15;
  -webkit-transform: rotate3d(0, 0, 1, 0deg);
  transform: rotate3d(0, 0, 1, 0deg);
  -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--wayra.button--inverted:hover::before {
  background-color: #fff;
}

.assestment a{
  color: white !important;
}
.button--wayra {
  background-color: rgb(7, 43, 21) !important;
  color: white !important;
}
.btn-primary {
  background-color: rgb(7, 43, 21) !important;
  color: white !important;
  border: 1px solid rgb(7, 43, 21) !important;
}

.btn-outline-primary {
  border: 1px solid rgb(7, 43, 21) !important;
  color: rgb(7, 43, 21) !important;
}
.btn-outline-primary:hover {
  color: white !important;
  background-color: rgb(7, 43, 21) !important;
}
.btn-primary:disabled {
  background-color: rgba(7, 43, 21, 0.5) !important;
  border: solid 1px rgba(7, 43, 21, 0.5) !important;
}


.mdk-nav:not(.active) {
  opacity: 0.7;
}
.mdk-nav {
  border-radius: 4px;
    margin: 8px 16px;
    max-height: 40px;
    padding: 8px 12px;
  color: #FFFFFF !important;
    font-size: 16px !important;
    font-family: "Inter" !important;
  text-decoration: none !important;
  display: flex !important;
  gap: 16px !important;
  align-items: center;
  
}

.mdk-nav:not(.active):hover {
  opacity: 1;
  background-color: #ffffff14 !important;
}

.mdk-nav i {
  font-size: 20px;
}


.mdk-nav.active {
  background-color: #23ce6b !important;
  color: #101828 !important;

}
.MuiPaper-elevation0, .MuiPaper-elevation1,  .MuiPaper-elevation2 {
  box-shadow: none !important;
  outline: none !important;
  border-radius: 4px !important;
  border: 2px solid rgb(234, 236, 240) !important;
}

.MuiPaper-elevation2 {
  border: none !important;
}

.MuiPaper-elevation1::before {
  content: none !important;
}
.MuiAccordion-root {
  margin-bottom: 16px;
}
.mdk-bkgrnd1 {
  box-shadow: none !important;
  border: 2px solid rgb(234, 236, 240) !important;
}

.score-widget {
  background-color: rgb(255, 255, 255);
  padding: 24px !important;
  border: 2px solid rgb(234, 236, 240);
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 24px;
  flex: 1;
}

.score-widget h3 {
  font-family: inter;
  line-height: 20px;
  color: rgb(52, 64, 84);
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 16px;
}
.stat {
  font-family: inter;
    line-height: 20px;
    color: rgb(29, 41, 57);
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 0px;
}
.score-card-container {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
}

.MuiTableCell-head {
  background-color: rgb(234, 236, 240) !important;
}
tr.MuiTableRow-root {
  background-color: white !important;
}

.MuiBox-root {
  box-shadow: none !important;
  transition: none !important;
}

.MuiBox-root .MuiPaper-root {
  display: grid;
  grid-template-columns: 1fr 250px;
}

.MuiBox-root .MuiPaper-root .MuiCardActions-root {
  justify-content: center;

}

.permissions {
  display: grid;
    grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.permissions label {
  display: grid;
  grid-template-columns: 1fr 64px;
}

.noResults {
  position: absolute;
    left: 50%;
    margin-left: -78px;
    top: 50%;
}

h1 {
font-family: inter;
    color: rgb(16, 24, 40);
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    line-height: 32px;
    margin-bottom: 0px;
}