@import url("https://fonts.googleapis.com/css?family=Muli&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@500&display=swap');

/* body {
  background-size: cover;
  background-position: center center;
  display: flex;
  font-family: "Lato", sans-serif;
  font-size: 140%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  min-height: 100vh;
} */

h4 {
  margin: 0 0 5px;
  font-weight: bold;
  color: #3a3b68;
  font-size: 32px; 
}

progress {
  width: 100%;
}

p {
  line-height: 1.5;
}

.container-tt {
  box-shadow: 0 4px 15px -5px rgba(99, 97, 97, 0.7);
  background-color: #fff;
  padding: 30px;
  width: 800px;
  max-width: 100%;
  text-align: center;
  margin: 50px auto 0 auto;
}

.container-tt h2 {
 font-weight: bold;
 color: #3a3b68;
}

.container-tt .text-tt {
  display: flex;
  flex-wrap: wrap;
}

.container-tt input {
  padding: 12px 15px;
  font-size: 20px;
  margin: 10px 0;
  width: 100%;
}

.start-btn-tt {
  border: 0;
  background-color: #3a3b68;
  color: #fff;
  font-size: 16px;
  padding: 12px 15px;
  width: 200px;
  max-width: 100%;
  border-radius: 40px;
  transition:all 0.5s ease-in-out 0s;
}
.start-btn-tt:hover {
  background-color: #1f2050;
}

.wpm-tt {
  margin-top: 20px;
  background: #3a3b68;
  padding: 10px 25px;
  color: #fff;
  border-radius: 10px;
  font-size: 20px;
}

.green {
  color: rgb(70, 196, 70);
}

.red {
  background-color: rgba(192, 23, 23, 0.5);
}

.underline {
  border-bottom: 1px solid #000;
}

.word {
  font-size: 20px;
  margin: 2px;
}

.share {
  color: #38a1f3;
}

@media screen and (max-width: 650px) {
  p {
    line-height: 1.3;
  }

  .container-tt {
    margin-bottom: 50px;
    padding: 10px;
    width: 100%;
  }

  .wpm-tt {
    display: none;
  }
}






/*start typing css here*/
/* .app_rightSideInContainer__1PgpV {
  width: 100%!important;
}
.style_main__1nkeN > div {
  border:none!important;
  margin: 0 20px 0 0;
  background-color: transparent!important;
}
.style_main__1nkeN > div span {
  display: block;
  text-align: center;
  font-weight: 400;
  font-size: .875rem;
  font-weight: bold;
  font-size: 14px;
}
.style_main__1nkeN > div span:first-child {
  font-weight: bold;
  width: 6rem;
  height: 6rem;
  line-height: 60px;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  font-size: 38px;
  margin: 0 0 5px 0;
  font-family: 'Oswald', sans-serif;
} 
.app_startButton__3eyCh {
  margin: 20px auto 10px auto!important;
  height: 50px!important;
  border-radius: 0!important;
  font-size: 15px!important;
  border-radius: 1.5rem!important;
    background: #4258b3!important;
    color: #fff;
    border: none!important;
    transition: all 0.5s ease-in-out 0s;
}
.app_startButton__3eyCh:hover {
  background: #243b98!important;
  transform: translate(0, -10px);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.style_main__1nkeN > div:nth-child(5) span:first-child {
  border: 4px solid #5266bb!important;
  margin: 0 0 0 50px;
  border-radius: 50%;
}
.style_main__1nkeN > div:nth-child(5) span:last-child {
  margin: 5px 0 0 50px;
  font-weight: bold;
  font-size: 18px;
}
.style_main__1GrZV {
  width: 100%!important;
}
.style_inputArea_div__1kGtj {
  width: 100%!important;
  margin-top: 0!important;
  min-height: 100px!important;
  color: green!important;
  font-size: 20px!important;
}
.style_last_section_div__3se2c {
  background: #fff!important;
  box-shadow: 0 9px 24px rgb(0 0 0 / 12%), 0 9px 24px rgb(0 0 0 / 12%)!important;
  border: none!important;
  border-radius: 0.5rem!important;
  font-size: 1.8rem!important;
  padding: 1em 0 1em 15px!important;
  font-family: 'Ibarra Real Nova', serif;
  color: #000!important;
}
.style_started_div__q0w3U  {
  background: #fff!important;
  box-shadow: 0 9px 24px rgb(0 0 0 / 12%), 0 9px 24px rgb(0 0 0 / 12%)!important;
  border: none!important;
  border-radius: 0.5rem!important;
  font-size: 2rem!important;
  color: rgba(0, 0, 0, 0.5);
  padding: 1em 0 1em 15px!important;
}
.style_main__1GrZV {
  border: none!important;
  background: transparent!important;
}
.style_last_section_div__3se2c {
  color: #000;
}
.style_started_div__q0w3U::after {
  left: 1rem!important;
  top: 4.7rem!important;
  width: 0.3em!important;
  height: 30px!important;
  margin: -33px 0 0 0!important;
  background-color: rgba(0, 0, 0, 0.19)!important;
}
.style_inputArea_div__1kGtj {
  background: #fff!important;
  padding: 1em 0 1em 15px!important;
}
.main-white {
  margin: 0!important;
}
.app_container__3uhxu {
  display: block!important;
  padding: 10px 20px!important;
}
.mdk-right-part .style_ended_div__8CQLY {
  background: #fff!important;
}
.mdk-right-part .style_ended_div_author_span__1coXi {
  background: #fff!important;
  padding: 5px!important;
  font-weight: bold!important;
}
.typing_widget {
  width: 100%;
  text-align: center;
  margin: 0 0 20px 0;
}
.typing_title {
  font-size: 3rem;
  padding: 0;
  margin: 0;
  color: #000;
  font-weight: bold;
  font-family: 'Kanit', sans-serif;
}
.main-white {
  
} */
/*end typing css here*/