@media all and (max-width: 991px) {
  #main-nav {
    display: block;
  }
  #desktop-menu {
    display: none;
  }
  #underneath-banner .col-sm-6 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .about-right-bg {
    padding: 30px 30px 0 0;
    margin: 30px 0 0 0;
  }
  #underneath-banner .col-sm-4 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .content-bg {
    margin: 0 0 15px 0;
  }
  #get-started .col-sm-4 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .started-widget {
    margin: 0 0 20px 0;
  }
  #services-area .col-sm-4 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .banner-area {
    margin: 60px 0 0 0;
  }
  .login-btn a {
    margin: 10px;
    background: #ef3139;
    color: #fff !important;
  }
}

@media all and (max-width: 768px) {
  #mdk-main-part .col-sm-2 {
    max-width: 100%;
    flex: 0 0 100%;
    background: transparent;
  }
  #mdk-main-part .col-sm-10 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .mdk-navbar-left {
    height: auto;
  }
  .mdk-navbar-left ul {
    width: 100%;
  }
}

@media all and (max-width: 600px) {
  .mobile-content {
    display: block;
    background: rgba(2, 45, 98, 0.1);
  }
  .banner-content {
    display: none;
  }
  .banner-title {
    font-size: 30px;
    line-height: 30px;
    color: #676c71;
  }
  .banner-title span {
    color: #676c71 !important;
    -webkit-text-stroke-width: inherit;
  }
  #banner-area p {
    color: #676c71;
  }
  #banner-area .button {
    border: 2px solid #676c71;
    color: #676c71;
  }
  .heading-title-white {
    font-size: 30px;
  }
  .heading-title {
    font-size: 30px;
  }
  .heading-title-black {
    font-size: 30px;
  }
  #services-area .col-sm-4 {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .slider-img {
    height: 300px;
  }
}
