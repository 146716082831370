.form-area {
	width:100%;
	
}
.form-group{
  margin-bottom: 10px;
}
.form-white {
	width:100%;
	background:rgba(255,255,255,0.3);
	padding:15px;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
	border-radius:10px;
}
.form-area .form-control {
	width:100%;
	height:auto;
	padding:10px;
	transition:all 0.5s ease-in-out 0s;
}
.form-area .form-control:hover {
	border:1px solid red;
}
.form-area .form-control:focus {
	border:1px solid red;
	outline:none;
	box-shadow:none;
}
.radio-widget {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  padding-right: 10px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.radio-widget input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color:#dacaca;
  border-radius: 50%;
}
.radio-widget:hover input ~ .checkmark {
  background-color: #ccc;
}
.radio-widget input:checked ~ .checkmark {
  background-color: #2196F3;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.container input:checked ~ .checkmark:after {
  display: block;
}
.radio-widget .checkmark:after {
 	top: 6px;
    left: 6px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}
.form-area label {
	font-weight:bold;
	color:rgba(0,0,0,0.5);
}
.form-area ::-webkit-input-placeholder {
  color: rgba(0,0,0,0.5);
  font-size:15px;
  letter-spacing:1px;
}
.form-area ::-moz-placeholder {
  color: rgba(0,0,0,0.5);
  font-size:15px;
  letter-spacing:1px;
}
.form-area :-ms-input-placeholder { 
  color: rgba(0,0,0,0.5);
  font-size:15px;
  letter-spacing:1px;
}
.form-area :-moz-placeholder {
  color: rgba(0,0,0,0.5);
  font-size:15px;
  letter-spacing:1px;
}
.common-top {
	width:100%;
	margin:15px 0 0 0;
}
.form-area textarea {
  height: 80px!important;
}

.mdk-de-timer span{
  font-size: 22px;    
  box-shadow: 5px 5px 24px 0px rgba(2, 45, 98, 0.1);
  border-radius: 40px;
  background-color: #f93a5a;  
  padding: 5px 25px;
  width: 100%;
  margin: 20px 0 0 0;
  color: #fff;
  text-align: center;
}
.textarea-height textarea {
  height: 110px!important;
  border: 1px solid #ced4da;
  transition: all 0.5s ease-in-out 0s;
}
.textarea-height textarea:hover {
  border: 1px solid #3a3b68;
}

.mdk-assetment-bg {
  width: 100%;
  padding: 10px;
  background-color: #eff3f8;
border: 1px solid #c7ddf7;
margin: 0 0 30px 0;
}

.mdk--testHeader {
  font-size: 14px;
  padding: 0;
  margin: 0;    
  color: #382d2d;
}
.mdk-assetment-bg i {
  margin: 0 10px 0 0;
}