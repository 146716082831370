.container {
  /* display: flex; */
  display: block;
  align-items: center;
  justify-content: center;
  /* border: 5px solid #907d7d; */
  margin: 0 20px;
  padding: 20px;
  border-radius: 20px;
}

.leftSideInContainer {
  /* width: 20%; */
}

.rightSideInContainer {
  /* width: 79%; */
}

/* .startButton {
  margin: 30px auto;
  cursor: pointer;
  width: 150px;
  height: 150px;
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */


.startButton {
  margin: 20px auto 10px auto!important;
  height: 50px!important;
  border-radius: 0!important;
  font-size: 15px!important;
  border-radius: 1.5rem!important;
    background: #4258b3!important;
    color: #fff;
    width: 200px;
    display: flex;
    align-items: center;
    border: none!important;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s ease-in-out 0s;
}
.startButton:hover {
  background: #243b98!important;
  transform: translate(0, -10px);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}