
.header-N {
  color: black;
  height: 100%;
  width: 300px;
  max-width: 100%;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  overflow-x: scroll;
  background: #3c4b64;
}

.nav {
  display: flex;
  color: black;
  overflow-x: auto;
  justify-content: left;
  padding: 0;
  /*padding-left: 30px;*/
  margin: 35px 0 0 0;
}

.module {
  cursor: pointer;
  /*margin-left: 30px; 
  margin-right: 30px;*/
  width: 100%;
  background: transparent;
  transition: all 0.5s ease-in-out 0s;

  font-weight: 500;
  font-size: 20px;
  color: white;

  transition: transform 50ms ease-out;
  text-decoration: none;
  padding: 15px;

  border-bottom: 1px solid #2d3d58;
}
.module:hover {
  background: #303c54;
  color: #fff;
  text-decoration: none;
}
.module:focus {
  background: #303c54;
  color: #fff;
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

.header__icon > .MuiSvgIcon-root {
  font-size: 30px;
  margin-left: auto;
  margin-right: auto;
}
.general {
  display: none;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-left: auto;
  margin-right: auto;
  font-size: 15px;
  color: black;
}

.header__icon:hover > p {
  display: inline;
}
.general--active > p {
  display: inline-flex !important;
}
.header__icon {
  cursor: pointer;
  color: black;
}

.header__icons {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

/*Hide scrollbar for Chrme,safari and OPera*/
.header-N::-webkit-scrollbar {
  display: none;
}
/*Hide scrollbar for IE,Edge and Safari*/
.header-N {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.left-header {
  width: 300px;
  height: 100vh;
  background: #f00;
}


@media all and (max-width:1199px){
  .mdk-navbar-left {
    width: 150px!important;
  }
}
.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 40px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

/* ---------------------------------------------------
  SIDEBAR STYLE
----------------------------------------------------- */

#sidebar {
  min-width: 250px;
  max-width: 250px;
  background: rgb(16, 24, 40);
  color: #fff;
  transition: all 0.3s;
height:100vh;
position:fixed;
top:0;
overflow-x:hidden;	
padding-bottom: 50px;
}

#sidebar.active {
  margin-left: -250px;
}

#sidebar .sidebar-header {
  padding: 17px 20px;
  background: rgb(16, 24, 40);
}

#sidebar ul.components {
  padding: 20px 0;  
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}

#sidebar ul li a {
  padding: 10px;
  font-size: 14px;
  display: block;
color:#fff;
}

#sidebar ul li a i{
  margin: 0 5px 0 0;  
}
#sidebar ul li a:hover {
  color: #2f3135;
  background: #fff;
}

.components .active{
  background: #2f3135
}

#sidebar ul li.active>a {
  color: #fff;
  background: #2f3135;
}
/*
#sidebar ul li.active>a,
a[aria-expanded="true"] {
  color: #fff;
  background: #6d7fcc;
}*/

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}


/* Sidebar */
/* 
.sidebar {
  width: 0;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidebar.open {
  width: TranslateX(250px);
}

.sidebar button {
  position: absolute;
  top: 20px;
  left: 15px;
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
} */
 .nav_lang_translater
 {
  padding: 0 15px;
  position: absolute;
  bottom: 10px;
  width: 100%;
 }
 .nav_lang_translater .css-yk16xz-control
 {
  background-color: transparent;
  color: #fff !important;
 }
 .nav_lang_translater .my-select__single-value
 {
  color: rgb(183,185,190);
 }
 .nav_lang_translater .select__menu{
  background-color: transparent !important;
 }




 .nav_lang_translater .my-select__control {
  background-color: transparent !important; /* Change the background color of the control (input area)
  color: #fff; */
}
 
.nav_lang_translater .my-select__menu {
  /* background-color: yellow !important;  */
  color: #000;
}
 
.nav_lang_translater .my-select__option--is-selected {
  background-color: rgb(35,206,107) !important; 
  color: white; 
}
 
.nav_lang_translater .my-select__option:hover {
  /* background-color: pink !important;  */
}