body {
    background:#ebeef6;
}
 .dashboard-widget {
    width:100%;
 }
 .dashboard-widget .col-sm-3 {
    padding-left:5px;
    padding-right:5px;
 }
 .blue-widget {
    width:100%;
    border-radius:5px;
    background: #3a7afe;
    transition:all 0.5s ease-in-out 0s;
 }
 .blue-widget:hover {
    transform:translate(0,-5px);
 }
 .pink-widget {
    width:100%;		
    border-radius:5px;
    background: #f93a5a;
    transition:all 0.5s ease-in-out 0s;
 }
 .pink-widget:hover {
    transform:translate(0,-5px);
 }
 .green-widget {
    width:100%;		
    border-radius:5px;
    background: #029666;
    transition:all 0.5s ease-in-out 0s;
 }
 .green-widget:hover {
    transform:translate(0,-5px);
 }
 .orange-widget {
    width:100%;		
    border-radius:5px;
    background: #f76a2d;
    transition:all 0.5s ease-in-out 0s;
 }
 .orange-widget:hover {
    transform:translate(0,-5px);
 }
 .blue-main {
    width:100%;
    padding:20px 30px;
 }
 .circle-right {
    width:100%;		
 }
 .white-circle {
    width:50px;
    height:50px;
    background:#fff;
    line-height:50px;
    border-radius:50%;
    float:right;
    justify-content:center;
    display:flex;
    align-items:center;
    text-align: center;
 }
 .left-dashbaord {
    width:100%;
 }
 .left-dashbaord i {
    font-size:25px;
    color:#fff;
    margin:0 0 10px 0;
 }
 .dashbaord-title {
    font-size:16px;
    padding:0;
    margin:0;
    color:#c5c5c5;
    font-weight:normal;
 }
 .total-title {
    font-size:20px;
    padding:0;
    margin:0;
    color:#005bea;
    font-weight:bold;
 }
 .tabs-widget {
    width:100%;
    margin:30px 0 0 0;
 }
 .white-area {
    width:100%;
    background:#fff;
    padding:30px;
 }
 .tabs-main .nav-pills .nav-link {
    padding:15px;
    font-size:16px;
    letter-spacing:0.5px;
 }
 .tabs-main .nav-pills .nav-link.active {
    background:#fff;
    color:#005bea;
 }
 .tabs-area {
    width:100%;
    padding:0 10px;
 }
 .new-link a {
    width:100%;
    display:inline-block;
    padding:8px 15px;
    background:#3a7afe;
    color:#fff;
    font-size:16px;
    text-align:center;
    border-radius:5px;
    transition:all 0.5s ease-in-out 0s;
 }
 .new-link a:hover {
    background:#1750c5;
    text-decoration:none;
 }
 .has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
.search-widget .form-control {
    padding:19px;
}
.search-widget .form-control:hover {
    border:1px solid #3a7afe;
}
.search-widget .form-control:focus {
    outline:none;
    box-shadow:none;
}
.requistion-title {
    font-size:30px;
    padding:0 0 15px 0;
    margin:0;
    font-weight:bold;
}
.tabs-main .nav {
   margin: 0;
}