.mdk--button {
  width: 200px;
    max-width: 100%;
    padding: 10px;
    background: #3a7afe;
    color: #fff;
    font-size:15px;
    letter-spacing:0.5px;
    font-weight: normal;
    border-radius: 40px;
    transition: all 0.5s ease-in-out 0s;
    display: inline-block;
    border: none;
    cursor: pointer;
}
.mdk--button:hover {
  background: #205cd4;  
}
.mdk--button:focus {
  background: #205cd4;  
    outline: none;
    box-shadow: none;
}
.set-question {
  width: 100%;
  text-align: right;
  margin: 10px 0 20px 0;
  background: #fff;
padding: 10px;
box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.mdk-set-btn{
  width: 200px;
  padding: 8px;
  font-size: 15px;
  letter-spacing: 0.5px;
  font-weight: normal;
  background: #029666;
  color: #fff;
  border-radius: 40px;
  border:none;
  transition: all 0.5s ease-in-out 0s;
}
.mdk-set-btn:hover {
  background: #026143;
  color: #fff; 
}
.mdk-set-btn:focus {
  background: #026143;
  color: #fff; 
  outline: none;
  box-shadow: none;
}

.Login_Form .password-input .form-control {
  padding-right: 50px;
}

.Login_Form .password-input {
  position: relative;
}

.Login_Form .password-input .password-toggle {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: transparent;
  border: none;
  box-shadow: none;
}
.password_lgn .password-input label
{
  display: none;
}
#inviteUserModal .modal-dialog{
  max-width: 700px;
}