
.audio-page {
    width:100%;				
}
.audio-white {
    width:500px;
    margin:0 auto;
    background:#fff;
    padding:70px 10px;				
    text-align:center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 10px 40px 0 rgba(0, 0, 0, 0.19);
    border-top-left-radius:20px;
    border-bottom-right-radius:20px;
}
.audio-content {
    width:500px;
    margin:0 auto;
}
.button {
    float: left;
    min-width: 150px;
    max-width: 150px;
    display: inline-block;
    margin: 0 0 0 10px;
    padding: 8px;				
    border: none;
    background: none;
    color: inherit;
    vertical-align: middle;
    position: relative;
    z-index: 1;
    border-radius:10px;
    -webkit-backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    cursor: pointer;
}
.button:focus {
    outline: none;
}
.button > span {
    vertical-align: middle;
}
.button--wayra {
    overflow: hidden;
    width: 245px;
    background:#7469d1;
    color:#fff;
    -webkit-transition: border-color 0.3s, color 0.3s;
    transition: border-color 0.3s, color 0.3s;
    -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--wayra:disabled{
    background-color: #e8e6e6 !important;
    color: #8f8a8a !important;
    cursor: auto;
}
.button--wayra:disabled:before
{
    display: none;
}
.button--wayra::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 150%;
    height: 100%;
    background: #37474f;
    z-index: -1;
    -webkit-transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
    transform: rotate3d(0, 0, 1, -45deg) translate3d(0, -3em, 0);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s, background-color 0.3s;
    transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
}
.button--wayra:hover {
    color: #fff;
    border-color: #3f51b5;
}
.button--wayra.button--inverted:hover {
    color: #3f51b5;
    border-color: #fff;
}
.button--wayra:hover::before {
    opacity: 1;
    background-color: #3f51b5;
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
    -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--wayra.button--inverted:hover::before {
    background-color: #fff;
}
.button-widget {
    display:flex;
    width:100%;
    justify-content:center;
    margin:20px 0 0 0;				
}

.blur-back {
    filter: blur(12px);
}
.card {
    margin:15px 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
}
.card_widget {
    padding: 15px;
}
select {
    border: 1px solid #fff;
    width: 200px;
    height: 40px;
    padding: 5px;
    margin: 0 0 0 10px;
    border-radius: 10px;
    background: #fff;
}
.audioContainer canvas {
    width: 70%!important;
    margin: auto;
    display: block;
    border-radius: 10px;
    border: 1px solid #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19);
}
.body_widget {
    margin: 15px 0 0 0;
    font-size: 25px;
}
.btn-box {
    display: flex;
    justify-content: center;
}