.form-area textarea {
    height:250px!important;
    resize:none;
}
.question-title {
    font-size:18px;
    padding:0 0 15px 0;
    margin:0;
    color:rgba(0,0,0,0.5);
}
.timer-widget {
    width:100%;
}

.article-heading{
    margin: 0 0 15px 0;
}

.mdk-de-timer span{
    font-size: 22px;    
    box-shadow: 5px 5px 24px 0px rgba(2, 45, 98, 0.1);
    border-radius: 40px;
    background-color: #f93a5a;  
    padding: 5px 25px;
    width: 100%;
    margin: 20px 0 0 0;
    color: #fff;
    text-align: center;
  }

  .mdk-assetment-bg {
    width: 100%;
    padding: 10px;
    background-color: #eff3f8;
  border: 1px solid #c7ddf7;
  margin: 0 0 30px 0;
  }

  .mdk--testHeader {
    font-size: 14px;
    padding: 0;
    margin: 0;    
    color: #382d2d;
  }