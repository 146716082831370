/* .main {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.value {
  font-size: 44px;
}

.name {
  font-size: 20px;
} */


/* .test > div { 
  border:none!important;
  margin: 0 20px 0 0;
  background-color: transparent!important;
} */

/* .newmain {
  border:2px solid red!important;
  margin: 0 20px 0 0;
  background-color: transparent!important;
}
.newmain span {
  display: block;
  text-align: center;
  font-weight: 400;
  font-size: .875rem;
  font-weight: bold;
  font-size: 14px;
}
.newmain span:first-child {
  font-weight: bold;
  width: 6rem;
  height: 6rem;
  line-height: 60px;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  font-size: 38px;
  margin: 0 0 5px 0;
  font-family: 'Oswald', sans-serif;
} */