.md--loginClass {
  width: 100%;
  background: #fff;
  padding: 20px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}
.md--loginClass .form-control {
  width: 100%;
  height: auto;
  padding: 15px;
  border-radius: 0;
  transition: all 0.5s ease-in-out 0s;
  border: 1px solid #eee;
}
.md--loginClass .form-control:hover {
  border: 1px solid #5c96d1;
}
.md--loginClass .form-control:focus {
  border: 1px solid #5c96d1;
  outline: none;
  box-shadow: none;
}

.body-widget {
  width: 100%;
}
.mdk--button-tabs {
  width: 100%;
  margin: 0 0 40px 0;
}
.mdk--loginMain {
  padding: 6em 0;
}
.mdk--loginCentre {
  width: 600px;
  max-width: 100%;
  margin: 0 auto;
  display: block;
}

.mdk--loginMain .module--1 {
  cursor: pointer;
  /*margin-left: 30px; 
  margin-right: 30px;*/
  width: 200px;
  background: #28588a;
  transition: all 0.5s ease-in-out 0s;

  font-weight: 500;
  font-size: 20px;
  color: white;

  transition: transform 50ms ease-out;
  text-decoration: none;
  padding: 15px;

  border-bottom: 1px solid #cecece;
  display: inline-block;

  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
}
.mdk--loginMain .module--1:hover {
  background: #07305b;
  color: #fff;
  text-decoration: none;
}
.mdk--loginMain .module--1:focus {
  background: #07305b;
  color: #fff;
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

.mdk--loginMain .module--2 {
  cursor: pointer;
  /*margin-left: 30px; 
  margin-right: 30px;*/
  width: 200px;
  background: #5c96d1;
  transition: all 0.5s ease-in-out 0s;

  font-weight: 500;
  font-size: 20px;
  color: white;

  transition: transform 50ms ease-out;
  text-decoration: none;
  padding: 15px;

  border-bottom: 1px solid #cecece;
  display: inline-block;

  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}
.mdk--loginMain .module--2:hover {
  background: #07305b;
  color: #fff;
  text-decoration: none;
}
.mdk--loginMain .module--2:focus {
  background: #07305b;
  color: #fff;
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

/* Login- new */

#login-page .container-fluid {
  padding-left:0;
  padding-right:0;
}
.login-left-main {
  position:relative;
  width:100%;
}
.login-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background: linear-gradient(0deg, #072B15 0%, rgba(7, 43, 21, 0.64) 37.27%, rgba(7, 43, 21, 0.16) 100%);;
  display:flex;
  align-items:center;		
  padding:70px;
}
#login-page .img-fluid {
  width:100%;
  height:100vh;
  object-fit:cover;
}
.heading-white {
  font-size:40px;
  padding:0 0 10px 0;
  margin:0;
  color:#fff;		
}
.heading-black {
  font-size:35px;
  padding:0 0 20px 0;
  margin:0;
  color:#000;		
}
.login-content {
  width:100%;
}
#login-page p {
  color:#fff;
  padding:0;
  margin:0;
  font-size:15px;
  line-height:28px;
}
.right-login {
  padding:30px 80px 30px 50px;
}
.login-page {
  background: #fff;
}
.right-login p {
  color: #000!important;
}
.right-login p a {
  /* color: #07305b!important; */
  transition: all 0.5s ease-in-out 0s;
}
.right-login p a:hover {
  /* color: #03182e!important; */
  text-decoration: underline;
}
#login-page .form-control {
  width: 100%;
  height: auto;
  padding: 12px;
  background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: .25rem;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
#login-page .form-control:hover {
  border: 1px solid #07305b;
}
#login-page .form-control:focus {
  border: 1px solid #07305b;
  outline: none;
  box-shadow: none;
}
#login-page .mdk--button {
  width: 270px!important;
  padding: 10px;
  background: #07305b;
  border:2px solid #07305b;
  margin: 0 0 15px 0;
  transition: all 0.5s ease-in-out 0s;
}
#login-page .mdk--button:hover {
  background: transparent;
  border:2px solid #07305b;
  color: #07305b;
}
#login-page .mdk--button:focus {
  background: transparent;
  border:2px solid #07305b;
  color: #07305b;
  outline: none;
  box-shadow: none;
}


/** Addition to above*/

.home-shape .shape1 {
  position: absolute;
  top: 55%;
  left: 2%;
  -webkit-animation: animationFramesOne 15s linear infinite;
          animation: animationFramesOne 15s linear infinite;
}

 

.home-shape .shape2 {
  position: absolute;
  top: 19%;
  left: 3%;
  -webkit-animation: pulse 4s infinite;
          animation: pulse 4s infinite;
}

 

.home-shape .shape3 {
  position: absolute;
  bottom: 5%;
  left: 40%;
  -webkit-animation: rotaed360 6s infinite linear;
          animation: rotaed360 6s infinite linear;
}

 

.home-shape .shape4 {
  position: absolute;
  top: 12%;
  left: 10%;
  -webkit-animation: rotaed360 6s infinite linear;
          animation: rotaed360 6s infinite linear;
}

 

.home-shape .shape5 {
  position: absolute;
  top: 15%;
  left: 62%;
  -webkit-animation: animationFramesOne 15s linear infinite;
          animation: animationFramesOne 15s linear infinite;
}

 

.home-shape .shape6 {
  position: absolute;
  bottom: 30%;
  right: 4%;
  -webkit-animation: animationFramesOne 15s linear infinite;
          animation: animationFramesOne 15s linear infinite;
}

.logo-main {
  width:200px;
  position:absolute;
  left:20px;
  top:20px;
}

.block-label { display: block;
  text-align: left;
  margin-bottom: 100px;
  }