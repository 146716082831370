.create-title {
    font-weight: bold;
font-size: 30px;
padding: 0 0 10px 0;
margin: 0;
}
.create-title span {
    color: #433673;
}
.mdk-divider {
    width: 50px;
    height: 1px;
    background: #433673;
    margin: 0 0 25px 0;
}
.form-white {
    width: 100%;
    background: #fff;
    padding: 15px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.form-white1 {
    width: 100%;
    background: #fff;
    padding: 15px;
    margin: 0 0 15px 0;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.form-repeat {
    width: 100%;
    margin: 0 0 15px 0;
}
.form-white2 {
    width: 100%;
}
.form-white2 .form-control {
    width: 100%;
    height: auto;
    padding: 10px;
    transition: all 0.5s ease-in-out 0s;
    border-radius: 0;
}
.form-white2 .form-control:hover {
    border:1px solid #3a3b68;
}
.form-white2 .form-control:focus {
    border:1px solid #3a3b68;
    outline: none;
    box-shadow: none;
}
.form-white .form-control {
    width: 100%;
    height: auto;
    padding: 10px;
    transition: all 0.5s ease-in-out 0s;
    border-radius: 0;
}
.form-white .form-control:hover {
    border:1px solid #3a3b68;
}
.form-white .form-control:focus {
    border:1px solid #3a3b68;
    outline: none;
    box-shadow: none;
}
.form-white label {
    font-size: 15px;
    font-weight: bold;
    color: #3a3b68;
}
.mdk-add-btn {
    width: 200px;
    max-width: 100%;
    padding: 10px;
    background: #3a7afe;
    color: #fff;
    font-size:15px;
    letter-spacing:0.5px;
    font-weight: normal;
    border-radius: 40px;
    transition: all 0.5s ease-in-out 0s;
    display: inline-block;
    border: none;
}
.mdk-add-btn:hover {    
    background: #205cd4;  
}
.mdk-add-btn:focus {    
    background: #205cd4;  
    outline: none;
    box-shadow: none;
}

.mdk-remove-btn {
    width: 200px;
    max-width: 100%;
    padding: 10px;
    background: #f93a5a;
    color: #fff;
    font-size:15px;
    letter-spacing:0.5px;
    font-weight: normal;
    border-radius: 40px;
    transition: all 0.5s ease-in-out 0s;
    display: inline-block;
    border: none;
    margin: 0 10px 0 0;
}
.mdk-remove-btn:hover {    
    background: #d0223f;    
}
.mdk-remove-btn:focus {    
    background: #d0223f;
    outline: none;
    box-shadow: none;
}

.question-title {
    padding: 0;
    margin: 0;
    text-align: left;
    display: inline-block;    
    font-size: 16px;
}
.content-left {
    width: 100%;
    text-align: left;
}